.contact-form-v1 {
	padding-bottom: 62px !important;

	.checkbox label {
		vertical-align: inherit;

		&:before {
			content: "";
			top: 6px;
		}

		&:after {
			content: "";
			top: 5px;
		}
	}

	.red {
		color: var(--color-red);
	}
}