.winter-bl {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 100px 50px 50px;
	position: relative;

	@media (max-width:1199px) {
		padding: 30px;

	}


	h2 {

		font-size: 60px;
		line-height: 0.9em;
		text-shadow: 0px 0px 16px rgba(0, 0, 0, 0.3);
		margin-bottom: 30px;

		@media (max-width:1199px) {
			font-size: 35px;
			line-height: 45px;

		}
	}

	h2,
	p {
		position: relative;
		color: var(--color-white);
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		height: 100%;
		left: 0;
		width: 100%;
		background-color: #00a1e2;
		opacity: 0.9;
		background-color: transparent;
		background-image: linear-gradient(180deg, #339EA563 0%, #00a1e2 100%);
		opacity: 1;
		filter: brightness(80%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
	}

	&:hover {
		&:before {
			background-color: #00a1e2;
			opacity: 0.9;
			filter: brightness(80%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
		}
	}

	&.left {

		background-image: url(RESOURCE/img/Winterberg-urlaub-wiese-frisches-grad-traum-Aussicht-scaled.jpg);

		@media (max-width:767px) {
			margin-bottom: 30px;
		}
	}

	&.right {
		background-image: url(RESOURCE/img/Winter-scaled.jpg);
	}
}

.feedback-show-v1 {
	text-align: left;

	.owl-stage {
		display: flex;
		padding: 10px 0;
	}

	.owl-item {
		padding: 20px 0;
	}

	.content {
		padding: 25px;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
		border-radius: 5px;
		height: 100%;

		margin: 15px;

		.fa {
			color: #f0ad4e;
		}

		&:hover {
			box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
		}
	}
}